import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from '@emotion/styled'

import Button from '../../core/Button'
import { TextBlock as TB, Separator } from '../../core/styles'

import constants from '../../../config/constants'
import spacings from '../../../config/spacings'
import colors from '../../../config/colors'
import { sheets } from '../../../config/typography'

import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

function WFields() {
  const { fields } = useStaticQuery(graphql`
    query {
      fields: allWfieldsJson {
        nodes {
          name
        }
      }
    }
  `)

  return (
    <Container>
      <Wrapper>
        <h2>Atuação</h2>
        <ContentWrapper>
          <TextBlock>
            A Lelis Engenharia vem crescendo sua área de atuação nos últimos
            anos. Os setores que atuamos mais frequentemente são:
          </TextBlock>
          <Ul>
            {fields.nodes.map((el, idx) => (
              <li key={idx.toString()}>
                <StyledIcon icon={faChevronRight} />
                {el.name}
              </li>
            ))}
          </Ul>
          <Separator />
          <TextBlock>
            Enquadrando-se ou não com um dos setores acima, sinta-se livre para
            entrar em contato conosco para que possamos analisar suas
            necessidades.
          </TextBlock>
          <Button
            border
            borderColor={colors.core.primary}
            textColor={colors.core.primary}
            hoverColor={colors.core.light}
            hoverBackground={colors.core.primary}
            as={Link}
            to="contato"
          >
            ENTRE EM CONTATO!
          </Button>
        </ContentWrapper>
      </Wrapper>
    </Container>
  )
}

const Container = styled.main`
  flex-grow: 1;

  display: flex;
  flex-direction: column;
`

const Wrapper = styled.section`
  max-width: ${constants.sizes.maxWidth}px;
  width: 100%;

  flex-grow: 1;

  margin: 0 auto;

  padding: ${spacings.large}rem;

  display: flex;
  flex-direction: column;

  color: ${colors.light.text};

  @media only screen and (max-width: ${constants.sizes.medium}px) {
    align-items: center;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  align-items: center;

  align-self: center;
`

const TextBlock = styled(TB)`
  max-width: 50rem;
`

const Ul = styled.ul`
  columns: 2;
  list-style: none;

  & li {
    ${sheets.headers.default};
  }

  @media only screen and (max-width: ${constants.sizes.medium}px) {
    columns: 1;
  }
`

const StyledIcon = styled(FAIcon)`
  margin-right: ${spacings.small}rem;
`

export default WFields
