import React from 'react'
import DefaultMaster from '../components/masters/DefaultMaster/DefaultMaster'
import WFields from '../components/containers/WFields/WFields'

function AboutPage() {
  return (
    <DefaultMaster title="Atuação">
      <WFields />
    </DefaultMaster>
  )
}

export default AboutPage
