import React from 'react'
import styled from '@emotion/styled'

import { sheets } from '../../../config/typography'
import spacings from '../../../config/spacings'
import colors from '../../../config/colors'
import { css } from '@emotion/core'

const emptyCss = css``

export default function Button({
  border = false,

  textColor = border ? colors.dark.text : colors.light.text,
  textStyle = sheets.default.smallish,
  hoverColor = textColor,
  backgroundColor = border ? colors.core.transparent : colors.core.light,
  hoverBackground = backgroundColor,

  borderColor = colors.core.light,
  borderRadius = `4rem`,
  borderWidth = `2px`,

  backgroundStyle = emptyCss,
  contentStyle = emptyCss,
  hoverStyle = emptyCss,
  hoverStyleBackground = emptyCss,
  hoverStyleContent = emptyCss,

  padding = `${spacings.default}rem ${spacings.large}rem`,

  transitionType = `ease-in-out`,
  transitionSpeed = `150ms`,

  children,
  ...props
}) {
  const wrapperProps = {
    ...props,
    padding,
    hoverBackground,
    hoverColor,
    borderRadius,
    hoverStyle,
    transitionType,
    transitionSpeed,
    hoverStyleBackground,
    hoverStyleContent,
  }

  const backgroundProps = {
    backgroundColor,
    hoverBackground,
    border,
    borderColor,
    borderRadius,
    borderWidth,
    backgroundStyle,
  }

  const contentProps = {
    textColor,
    textStyle,
    hoverColor,
    contentStyle,
  }

  return (
    <ButtonWrapper {...wrapperProps}>
      <ButtonBackground {...backgroundProps} />
      <ButtonContent {...contentProps}>{children}</ButtonContent>
    </ButtonWrapper>
  )
}

const ButtonBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;

  height: 100%;
  width: 100%;

  border: ${({ border, borderWidth }) => (border ? borderWidth : 0)} solid
    ${({ borderColor }) => borderColor};
  border-radius: ${({ borderRadius }) => borderRadius};

  background: ${({ backgroundColor }) => backgroundColor};

  z-index: 0;

  ${({ backgroundStyle }) => backgroundStyle}
`

const ButtonContent = styled.span`
  z-index: 1;
  color: ${({ textColor }) => textColor};

  ${({ textStyle }) => textStyle}
  font-weight: 500;

  ${({ contentStyle }) => contentStyle}
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;

  position: relative;

  border-radius: ${({ borderRadius }) => borderRadius};

  padding: ${({ padding }) => padding};

  &,
  & > * {
    transition: all ${({ transitionType }) => transitionType}
      ${({ transitionSpeed }) => transitionSpeed};
  }

  &:hover {
    cursor: pointer;

    & > ${ButtonBackground} {
      background: ${({ hoverBackground }) => hoverBackground};
      ${({ hoverStyleBackground }) => hoverStyleBackground};
    }

    & > ${ButtonContent} {
      color: ${({ hoverColor }) => hoverColor};
      ${({ hoverStyleContent }) => hoverStyleContent};
    }

    ${({ hoverStyle }) => hoverStyle};
  }
`

export { Button }
